@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

.author__avatar img{
    max-width: 200px;
    min-width: 100px;
    border-radius: 90%
}

.page__content {
    p,
    li,
    dl {
      font-size: 0.9em;
    }
  }